<template>
  <div id="couponModel" v-if="mackShow">
    <van-dialog v-model="isShow" :showConfirmButton="false" closeOnClickOverlay>
      <div class="main">
        <div class="title">{{text.title}}</div>
        <ul>
          <div class="list_main">
            <li v-for="(item,index) in list" :key="index">
              <voucherItem :showInvalidTime="false" :data="item" :index="index" @useClick="useClick" :showBtns="true"/>
            </li>
          </div>
        </ul>
        <p class="tip">前往 我的-优惠券 查看详情</p>
      </div>
      <div class="close_icon">
        <van-icon name="close" color="#fff" @click="isShow = false"/>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { isFC } from "@/utils/index";
import { Dialog } from "vant";
import voucherItem from "@/components/voucherItem";

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
    voucherItem
  },

  data() {
    return {
      text:{
        title:'温馨提示'
      },
      list: [],
      isFC,
      mackShow: true,
      isShow: false,
      callback: () => {}
    };
  },
  methods: {
    useClick() {
      this.isShow = false;
      this.callback()
    }
  },
  mounted() {
    let timer = setTimeout(() => {
      this.isShow = true;
      clearTimeout(timer);
    }, 0);
  },
  created() {}
};
</script>

<style lang="less" scoped>
::v-deep .van-dialog {
  background-color: transparent !important;
}
::v-deep .voucherItem_right{
    background-color: #fff1df !important;
    .voucherItem_right_btn{
      margin-right: 4px !important;
    }
}
::v-deep .voucherItem_left {
  height: 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;
  line-height: 70px !important;
  &::before {
    background-color: transparent !important;
  }
  &::after {
    background-color: transparent !important;
  }
}
::v-deep .remark{
  font-size: 10px;
  margin-top: 6px;
}
::v-deep .rule {
  width: 100% !important;
}
::v-deep .voucherItem {
  height: 70px !important;
}
#couponModel {
  .main {
    background-image: linear-gradient(to bottom, #ff4847, #ffe1b0);
    padding: 16px 10px;
    position: relative;
    border-radius: 16px;
    .title {
      text-align: center;
      color: #fff;
      padding: 16px;
      font-size: 20px;
      color: #fdd99c;
      font-weight: bold;
    }
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      &::before {
        content: "";
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 12px;
        border: 3px solid #ffad93;
        border-radius: 9999px;
      }
      .list_main {
        overflow: scroll;
        height: 50vh;
        background-image: linear-gradient(to bottom, #fbb5b1, #ffffff, #ffffff);
        padding: 12px 6px;
        transform: translateY(-6px);
        width: 95%;
        border-radius: 0 0 12px 12px;
      }
    }
    .tip{
        text-align: center;
        font-size: 14px;
        color: #ff4948;
    }
  }
  .close_icon {
    text-align: center;
    margin: 0 auto;
    color: #fff;
    font-size: 30px;
    text-align: center;
    margin-top: 16px;
  }
}
</style>
  