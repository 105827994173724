<template>
  <div class="back-to-top" v-show="showBtn" @click="scrollToTop" v-if="ifShow">
    <van-icon name="back-top" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBtn: false,
      ifShow: true
    };
  },
  watch: {
    $route: {
      handler(val) {
        const name = val.name;
        this.ifShow = name && name != "goodsDetails";
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showBtn = window.pageYOffset > 180;
    },
    scrollToTop() {
      const duration = 500; // 滚动到顶部的时间
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const step = Math.floor((scrollTop / duration) * 16.7); // 每 16.7 毫秒滚动的距离
      const timer = setInterval(() => {
        if (window.pageYOffset > 0) {
          window.scrollBy(0, -step);
        } else {
          clearInterval(timer);
        }
      }, 16.7);
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style scoped>
.back-to-top {
  position: fixed;
  bottom: 25vh;
  right: 3px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(51, 51, 51, 0.56);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  opacity: 0.7;
  z-index: 150;
  transition: all 0.3s;
}

.back-to-top:hover {
  opacity: 1;
}
</style>