import Vue from 'vue'

import {
    Toast,
    Button,
    Form,
    Field,
    Swipe,
    SwipeItem,
    Icon,
    Divider,
    Tab,
    Tabs,
    PullRefresh,
    List,
    Overlay,
    Area,
    Popup,
    Checkbox,
    CheckboxGroup,
    SwipeCell,
    Stepper,
    Empty,
    Image as VanImage,
    Loading,
    Step,
    Steps,
    TabbarItem,
    ActionSheet,
    Dialog,
    ShareSheet,
    CountDown,
    Badge,
    Lazyload,
    NoticeBar,
    Sticky,
    Popover
} from 'vant';
Vue.use(Button)
    .use(Form)
    .use(Field)
    .use(Swipe)
    .use(SwipeItem)
    .use(Divider)
    .use(Icon)
    .use(Tab)
    .use(Tabs)
    .use(PullRefresh)
    .use(List)
    .use(Overlay)
    .use(Area)
    .use(Popup)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(SwipeCell)
    .use(Stepper)
    .use(Empty)
    .use(VanImage)
    .use(Loading)
    .use(Step)
    .use(Steps)
    .use(TabbarItem)
    .use(ActionSheet)
    .use(Dialog)
    .use(ShareSheet)
    .use(CountDown)
    .use(Badge)
    .use(Lazyload)
    .use(NoticeBar)
    .use(Sticky)
    .use(Popover)

import { Notify } from 'vant';
Notify.setDefaultOptions({ type: 'primary' })

Vue.prototype.$Toast = (msg) => {
    let timer = setTimeout(() => {
        Toast(msg)
        clearTimeout(timer)
    }, 150)
}