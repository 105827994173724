import Vue from 'vue';
import ele from './index.vue';
let confirmConstructor = Vue.extend(ele);
let couponModel = function (list,data) {
    console.log(list,'couponModel');
    // list = [{
    //     invalidTime:'',
    //     status:'1',
    //     remark: "赠送 好运十倍（10元） * 2张",
    //     amount:0,
    //     limitAmount:'0',
    //     name:'9.18直播活动赠送'
    // }]
    if (list && list.length > 0) {
        return new Promise((res, rej) => { //promise封装，ok执行res，no执行rej
            let confirmDom = new confirmConstructor({ el: document.createElement('div') })
            document.body.appendChild(confirmDom.$el); //new一个对象，然后插入body里面
            confirmDom.list = list;
            confirmDom.text = data

            confirmDom.callback = function () {
                res()
            }

            confirmDom.confirm = function () {
                confirmDom.isShow = false
                let timer = setTimeout(() => {
                    confirmDom.mackShow = false
                    clearTimeout(timer)
                }, 150)
                res()
            }
            confirmDom.cancel = function () {
                confirmDom.isShow = false
                let timer = setTimeout(() => {
                    confirmDom.mackShow = false
                    clearTimeout(timer)
                }, 150)
                rej()
            }
        })
    }
}
export default couponModel;