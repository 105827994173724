import { userStore } from '@/pinia'
import { mapWritableState } from 'pinia'
import { service } from '@/utils/index'

export default {
    data() {
        return {
            service_phone: service.phone,
            service_weChat: service.weChat,
            service_weChatQRcode: service.weChatQRcode
        }
    },
    methods: {
        async callUp() {
            // const model = (await import('@/components/model/index')).default
            // console.log(model);
            // model({title:'确认拨打电话吗？'}).then(() => {
            //     window.location.href = "tel:" + this.service_phone;
            // })
            window.location.href = "tel:" + this.service_phone;
        },
        //未注册弹框
        notRegister() {
            // this.$model({
            //     content: "您暂未注册，请先注册后再操作",
            //     okText: "注册",
            //     cancelText: "再看看"
            // }).then(() => {
            //     this.$router.push({ name: "register" });
            // }).catch(() => {})
            this.$login()
        }
    },
    computed: {
        isWxPay() {
            return this.myFreight > 0
        },
        payType() {   //支付方式  0到付  1在线支付+到付  2在线支付
            if (this.myFreight == 0) {
                return '0'
            } else if (this.myFreight > 0) {
                return '1'
            } else {
                return '2'
            }
        },
        ...mapWritableState(userStore, ['userInfo', "myFreight"])
    },
    created() { },
}