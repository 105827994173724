<template>
  <div class="checkbox" @click="onClick" :class="{checkbox_active : isChecked, disabled}">
    <van-icon v-if="isChecked" name="success" color="#fff" />
  </div>
</template>

<script>
export default {
  props: {
    isChecked: {
      type: Boolean,
      required: true
    },
    disabled:{
      type:Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      if(!this.disabled){
        this.$emit("onClick");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.checkbox {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  border: 1px solid #ccc;
  font-size: 16px;
  padding-top: 2px;
  background-color: #fff;
}
.checkbox_active {
  background-color: #f94d2b;
  color: #fff;
  text-align: center;
  border: none;
  font-size: 18px;
}
.disabled{
  background-color: rgb(220, 220, 220);
}
</style>