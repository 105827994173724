<template>
  <div id="app">
    <div class="app_mask" v-if="inWeChat"></div>
    <keep-alive :include="['layout','orderList']">
      <router-view />
    </keep-alive>
    <backToTop />
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { userStore } from "@/pinia";
import {
  getParams,
  _getVersion,
  setSessionStorage,
  uploadUserCode,
  inWeChat,
  judgeAppIds
} from "@/utils";
import backToTop from "@/components/backToTop";
export default {
  components: { backToTop },
  data() {
    return {
      inWeChat: inWeChat()
    };
  },
  methods: {
    //初始化
    async init() {
      // 商品Id
      const productId = getParams("productId");
      if (productId) setSessionStorage("productId", productId);
      //渠道来源
      const source = getParams("source");
      if (source) setSessionStorage("source", source);
      //清缓存
      const reset = getParams("reset");
      if (reset) return localStorage.clear();
      //appId
      const appId = getParams("appId");
      if (appId) this.setAppId({ appId });
      //物料id
      const mid = getParams("mid");
      if (mid) setSessionStorage("mid", mid);
      //邀请Id
      const shareUserId = getParams("shareUserId");
      if (shareUserId) setSessionStorage("shareUserId", shareUserId);
      //直接进入 不去获取版本号跳转详情页
      const enter = getParams("enter");
      if (enter) return;
      //extUserId
      const extUserId = getParams("extUserId");
      //appIds  临时appid 
      if(!judgeAppIds()){
        return
      }
      //跳转操作
      const { protocol, host } = window.location;
      if (appId && extUserId) {
        this.setAppId({ appId, extUserId });
        this.login().then(() => {
          _getVersion(protocol + "//" + host + "/" + "goodsDetails");
        });
      } else if (appId && !extUserId) {
        this.setAppId({ appId });
        _getVersion(protocol + "//" + host + "/" + "goodsDetails");
      }
    },
    ...mapActions(userStore, ["login", "setAppId", "getFreight"])
  },
  created() {
    this.init();
    this.getFreight();
  },
  mounted() {
    uploadUserCode();
  }
};
</script>

<style lang="less">
#app {
  font-size: 16px;
}
.app_mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: #eee;
  z-index: -2;
}
* {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }
}
.selectSku {
  .popup_btn {
    .popup_btn_btnText {
      span {
        line-height: 28px;
      }
      .text1 {
        font-size: 15px;
        margin-right: 12px;
      }
      .text4 {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
}
</style>
