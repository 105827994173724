<template>
  <div :class="{voucherItem:true,Invalid:data.status == 3}">
    <div class="voucherItem_left">
      <p v-if="data.amount > 0">
        <span class="voucherItem_left_icon">￥</span>
        <span class="voucherItem_left_num">{{data.amount}}</span>
      </p>
      <p v-else-if="data.amount == 0">
        赠票券
      </p>
    </div>
    <div class="voucherItem_right">
      <div class="voucherItem_right_main">
        <p class="name">{{data.name}}</p>
        <p class="rule">{{data.limitAmount == 0 ? '无使用门槛' : `商品金额满${data.limitAmount}元可用`}}</p>
        <p class="remark" v-if="data.amount == 0">{{data.remark}}</p>
        <p class="invalidTime" v-if="showInvalidTime" >{{time().text}}{{time().time}}</p>
      </div>
      <div class="voucherItem_right_btn" v-if="showBtns">
        <!-- <span @click="onClick(1)" v-if="isSelec">选择</span> -->
        <myChecked
          v-if="isSelec"
          @onClick="onClick(1)"
          :isChecked="data.id == selectedVoucherInfo?.id"
          :disabled="disabledSelec"
        />
        <span @click.stop="onClick(2)" v-else-if="data.status == 1">去使用</span>
      </div>
    </div>
  </div>
</template>

<script>
import myChecked from "@/components/myChecked.vue";
import { userStore } from "@/pinia";
import { mapWritableState } from "pinia";
import router from "@/router";
export default {
  components: { myChecked },
  props: {
    isSelec: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
    selectedData: {
      type: Object,
      default: () => {}
    },
    disabledSelec: {
      type: Boolean,
      default: false
    },
    showBtns: {
      type: Boolean,
      default: true
    },
    showInvalidTime:{
      type:Boolean,
      default:true
    }
  },
  computed: {
    ...mapWritableState(userStore, ["selectedVoucherInfo"])
  },
  methods: {
    onClick(e) {
      if (e == 1) {
        this.selectedVoucherInfo = this.data;
        this.$emit("select", this.index);
      } else if (e == 2) {
        this.$emit("useClick", this.index);
        const current = router.history.current.name;
        if (current != "goodsDetails") {
          router.replace({ name: "goodsDetails" });
        }
      }
    },
    time() {
      let text = "有效期至：";
      let time =
        this.data.invalidTime.substr(0, 4) == "9999"
          ? "永久"
          : this.data.invalidTime;
      if (this.data.status == 2) {
        text = "使用时间：";
        time = this.data.usedTime;
      } else if (this.data.status == 3) {
        text = "过期时间：";
      }
      return { text, time };
    }
  }
};
</script>

<style lang="less" scoped>
.voucherItem {
  height: 80px;
  margin: 12px 0 0;
  display: flex;
  .voucherItem_left {
    height: 100%;
    width: 85px;
    line-height: 80px;
    background-image: linear-gradient(to right, #d32c34e6, #fe4747d9);
    border-right: 1px dashed #823f3f;
    position: relative;
    border-radius: 6px 0 0 6px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #eff0f1;
      position: absolute;
      right: -5px;
      top: -5px;
    }
    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #eff0f1;
      position: absolute;
      right: -5px;
      bottom: -5px;
    }
    .voucherItem_left_num {
      font-size: 30px;
    }
  }
  .voucherItem_right {
    flex: 1;
    display: flex;
    background-color: #fff;
    border-radius: 0 6px 6px 0;
    .voucherItem_right_main {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      font-size: 12px;
      .rule {
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .invalidTime {
        margin-top: 6px;
      }
    }
    .voucherItem_right_btn {
      margin-right: 16px;
      display: flex;
      align-items: center;
      font-size: 12px;
      span {
        background-color: #f65b5c;
        padding: 3px 8px;
        display: inline-block;
        color: #fff;
        border-radius: 9999px;
        white-space: nowrap;
      }
    }
  }
}
.Invalid{
  position: relative;
  overflow: hidden;
  &::before{
    content: "已过期";
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(15%,-15%) rotate(45deg);
    color: #dcdcdc;
    border-radius: 50%;
    border: 1px solid #ccc;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    padding: 3px;
    font-size: 12px;
  }
  .voucherItem_left{
    background: #dcdcdc;
  }
}
</style>