import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/LayoutView.vue'
import wx from 'weixin-js-sdk'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
nprogress.configure({ showSpinner: false })
import { Toast } from 'vant';
import { userStore } from "@/pinia";
import { getOrderSource, getSessionStorage, getlocalStorage, toast } from '@/utils'
Vue.use(VueRouter)

const routes = [
  {
    path: '/Layout',
    // name: 'layout',
    component: Layout,
    redirect: '/commodity',
    meta: {
      keepAlive: true,
    },
    children: [
      {
        path: '/goodsDetails',
        name: 'goodsDetails',
        component: () => import('@/pages/goodsDetails.vue'),
        meta: {
          keepAlive: true,
          isLogin: false,
          title: ''
        }
      },
      {
        path: '/my',
        name: 'my',
        component: () => import('@/pages/myView.vue'),
        meta: {
          keepAlive: true,
          isLogin: true,
          title: '我的'
        }
      },
      {
        path: '/my-new',
        name: 'my-new',
        component: () => import('@/pages/my-new.vue'),
        meta: {
          keepAlive: true,
          isLogin: true,
          title: '我的'
        }
      },
    ]
  },
  {
    path: '/confirmOrder',  //立即购买确认下单
    name: 'confirmOrder',
    component: () => import('@/pages/confirmOrder.vue'),
    meta: {
      keepAlive: false,
      isLogin: true,
      title: '确认订单'
    }
  },
  {
    path: '/confirmCart',   //购物车确认下单
    name: 'confirmCart',
    component: () => import('@/pages/confirmCart.vue'),
    meta: {
      keepAlive: false,
      isLogin: true,
      title: '确认订单'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/pages/registerView.vue'),
    meta: {
      keepAlive: false,
      isLogin: false,
      title: '注册'
    }
  },
  {
    path: '/logistics_detail',
    name: 'logistics_detail',
    component: () => import('@/pages/logistics_detail.vue'),
    meta: {
      keepAlive: false,
      isLogin: true,
      title: '物流详情'
    }
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('@/pages/orderDetail.vue'),
    meta: {
      keepAlive: false,
      isLogin: true,
      title: '订单详情'
    }
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('@/pages/privacyPolicy.vue'),
    meta: {
      keepAlive: false,
      isLogin: false,
      title: '隐私策略'
    }
  },
  {
    path: '/TOS',
    name: 'TOS',
    component: () => import('@/pages/TOS.vue'),
    meta: {
      keepAlive: false,
      isLogin: false,
      title: '服务协议'
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/pages/cart.vue'),
    meta: {
      keepAlive: false,
      isLogin: true,
      title: '订票仓'
    }
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: () => import('@/pages/my/orderList.vue'),
    meta: {
      keepAlive: true,
      isLogin: true,
      title: '我的订单'
    }
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('@/pages/my/addressList.vue'),
    meta: {
      keepAlive: true,
      isLogin: true,
      title: '收货地址'
    }
  },
  {
    path: '/mallScore',
    name: 'mallScore',
    component: () => import('@/pages/my/mallScore.vue'),
    meta: {
      keepAlive: true,
      isLogin: true,
      title: '积分明细'
    }
  },
  {
    path: '/voucherList',
    name: 'voucherList',
    component: () => import('@/pages/my/voucher/list.vue'),
    meta: {
      keepAlive: false,
      isLogin: true,
      title: '优惠券'
    }
  },
  {
    path: '/voucherList',
    name: 'voucherList',
    component: () => import('@/pages/my/voucher/list.vue'),
    meta: {
      keepAlive: false,
      isLogin: true,
      title: '优惠券'
    }
  },
  {
    path: '/getVoucher',
    name: 'getVoucher',
    component: () => import('@/pages/getVoucher.vue'),
    meta: {
      keepAlive: false,
      isLogin: false,
      title: '送福到家'
    }
  },
  {
    path: '/getVoucherList',
    name: 'getVoucherList',
    component: () => import('@/pages/getVoucherList.vue'),
    meta: {
      keepAlive: false,
      isLogin: false,
      title: '送福到家'
    }
  },
  {
    path: '/getVoucher-notLogin',
    name: 'getVoucher-notLogin',
    component: () => import('@/pages/getVoucher-notLogin.vue'),
    meta: {
      keepAlive: false,
      isLogin: false,
      title: '送福到家'
    }
  },
  {
    path: '/luckyDraw',
    name: 'luckyDraw',
    component: () => import('@/pages/my/luckyDraw.vue'),
    meta: {
      keepAlive: false,
      isLogin: false,
      title: '积分抽奖'
    }
  },
  {
    path: '/redeemHelp',
    name: 'redeemHelp',
    component: () => import('@/pages/my/redeemHelp.vue'),
    meta: {
      keepAlive: true,
      isLogin: true,
      title: '兑奖指引'
    }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/pages/my/help.vue'),
    meta: {
      keepAlive: true,
      isLogin: true,
      title: '帮助中心'
    }
  },
  {
    path: '/h5PayCallback',
    name: 'h5PayCallback',
    component: () => import('@/pages/h5PayCallback.vue'),
    meta: {
      keepAlive: true,
      isLogin: true,
      title: ''
    }
  },
  {
    path: '/blindBoxDetails',
    name: 'blindBoxDetails',
    component: () => import('@/blindBoxPage/index.vue'),
    meta: {
      keepAlive: false,
      isLogin: false,
      title: '刮刮乐盲盒'
    }
  },
  {
    path: '/blindBoxOrder',
    name: 'blindBoxOrder',
    component: () => import('@/blindBoxPage/confirmOrder.vue'),
    meta: {
      keepAlive: false,
      isLogin: true,
      title: '确认订单'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/pages/404.vue'),
    meta: {
      keepAlive: false,
      isLogin: false,
      title: '出错啦！！！'
    }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/pages/testView.vue'),
    meta: {
      keepAlive: true,
      isLogin: false,
      title: '测试页面'
    }
  },
]

const router = new VueRouter({
  mode: "history",
  base: '',
  routes: [...routes]
})
//路由前置守卫
router.beforeEach(async (to, from, next) => {
  if (to.name == '404') {
    next()
  } else {
    const _userStore = userStore()
    if (to.meta.isLogin) {
      if (_userStore.token) {
        nprogress.start()
        nextBefore(to, from, next)
      } else {
        const vm = new Vue()
        vm.$login().then(() => {
          nextBefore(to, from, next)
        })
      }
    } else {
      nextBefore(to, from, next)
    }
  }
}
);


function nextBefore(to, from, next) {
  console.log(to);
  const { appId, appIds, mid } = to.query
  const storageMid = getSessionStorage('mid')
  const storageAppId = getlocalStorage('appId')
  if (appId || appIds) {
    if (mid) {
      next()
    } else {
      if (storageMid) {
        next({ ...to, query: { ...to.query, mid: storageMid, source: getOrderSource() } })
      } else {
        next()
      }
    }
  } else {
    if (storageAppId) {
      next({ ...to, query: { ...to.query, appIds: storageAppId, source: getOrderSource() } })
    } else {
      toast('链接已失效，请联系客服重新获取')
    }
  }
}

//后置首位
router.afterEach((to) => {
  if (window._hmt) {
    window._hmt.push(['_trackPageview', to.path]);
  }
  window.scroll(0, 0)
  //关闭微信浏览器分享等功能
  wx.hideOptionMenu()
  //
  Toast.clear()
  //关闭进度条
  nprogress.done()
  //设置浏览器title
  if (to.meta.title) {
    console.log(document.title);
    document.title = to.meta.title;
  }
})
//解决每次打包部署后部分路由卡死问题
router.onError((err) => {
  routerErr_failed(err)
})
//
router.onReady(() => {
  routerOnReady_failed()
})

// 解决next重定向报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


export default router
/* webpackChunkName: "about" */


function routerErr_failed(err) {
  const jsPattern = /Loading chunk (\S)+ failed/g
  const cssPattern = /Loading CSS chunk (\S)+ failed/g
  const isChunkLoadFailed = err.message.match(jsPattern) || err.message.match(cssPattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    localStorage.setItem('targetPath', targetPath)
    sessionStorage.setItem('targetPath', targetPath)
    window.location.reload()
  }
}

function routerOnReady_failed() {
  const targetPath = localStorage.getItem('targetPath')
  const tryReload = localStorage.getItem('tryReload')
  if (targetPath) {
    localStorage.removeItem('targetPath')
    if (!tryReload) {
      router.replace(targetPath)
      localStorage.setItem('tryReload', true)
    } else {
      localStorage.removeItem('tryReload')
    }
  }
}